<template>
  <v-container fluid class="container">
    <headerr>Interventi</headerr>

    <div class="filtro">
      <v-row class="ma-0 pa-0">
        <v-col cols="5" lg="5">
          <v-select
            :items="specialità"
            placeholder="Seleziona una specialità"
            v-model="specialitàSelezionata"
            hide-details
            rounded
            filled
          ></v-select>
        </v-col>
        <v-col cols="5" lg="6">
          <v-text-field
            @change="test()"
            placeholder="Cerca intervento"
            v-model.lazy="search"
            hide-details
            rounded
            filled
          ></v-text-field>
        </v-col>
        <v-col cols="2" lg="1">
          <v-btn
            class="py-2 mt-0"
            elevation="0"
            :color="specialitàSelezionata == 'Preferiti' ? 'warning' : 'grey'"
            @click="mostraPreferiti"
            block
            height="100%"
            rounded
            dark
          >
            <v-icon>mdi-star</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div class="interventi pa-4 pt-2">
      <v-card
        v-for="(intervento, index) in interventi"
        :key="index"
        class="d-flex align-space-between pa-0 mb-4"
        color="tertiary"
        elevation="0"
        rounded
        block
      >
        <v-row class="ma-0 pa-0">
          <v-col
            cols="11"
            class="ma-0 pa-5"
            @click="$router.push(`interventi/${intervento.id}`)"
          >
            <h3 style="text-transform: uppercase;">{{ intervento.nome }}</h3>
            <p class="text--disabled">
              {{ configurazione(intervento.configurazione).codice }}
            </p>
          </v-col>
          <v-col cols="1" class="ma-0 pa-0">
            <v-btn
              class="preferiti"
              height="100%"
              block
              :color="preferito(intervento.id) ? 'warning' : '#D3D3D3'"
              :dark="!preferito(intervento.id)"
              @click="modificaPreferito(intervento.id)"
              elevation="0"
              small
            >
              <v-icon>mdi-star</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { database } from "@/plugins/firebase";
import firebase from "firebase/compat";

export default {
  data() {
    return {
      specialitàSelezionata: "Preferiti",
      prevednttntere: "",
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      id: "struttura/id",
      preferiti: "struttura/preferiti",
      specialità: "liste/specialità",
      configurazione: "categorie/configurazione",
    }),
    interventi() {
      const { specialitàSelezionata } = this;
      const interventi = this.$store.getters["categorie/interventi"];
      if (specialitàSelezionata == "Preferiti") {
        return interventi.filter(({ id, nome }) => {
          return this.preferiti.includes(id) && this.visibile(nome);
        });
      }
      // console.log("intereventi: " + interventi);
      return interventi.filter(({ nome, specialita }) => {
        return specialita === specialitàSelezionata && this.visibile(nome);
      });
    },
  },
  methods: {
    test() {
      // console.log("test");
    },
    mostraPreferiti() {
      if (this.specialitàSelezionata == "Preferiti") {
        if (this.prevednttntere == "")
          this.specialitàSelezionata = this.specialità[0];
        else this.specialitàSelezionata = this.prevednttntere;
      } else {
        (this.prevednttntere = this.specialitàSelezionata),
          (this.specialitàSelezionata = "Preferiti");
      }
    },
    visibile(nome) {
      const { search } = this;
      if (search === "") {
        return true;
      }

      //console.log(nome,nome.toUpperCase().includes(search.toUpperCase()))
      return nome.toUpperCase().includes(search.toUpperCase());
    },
    preferito(id) {
      return this.preferiti.includes(id);
    },
    async modificaPreferito(intervento) {
      const { id } = this;

      if (this.preferito(intervento)) {
        database
          .collection("strutture")
          .doc(id)
          .update({
            preferiti: firebase.firestore.FieldValue.arrayRemove(intervento),
          });
      } else {
        database
          .collection("strutture")
          .doc(id)
          .update({
            preferiti: firebase.firestore.FieldValue.arrayUnion(intervento),
          });
      }
    },
  },
};
</script>

<style>
.container {
  display: flex;
  height: calc(100vh - 50px);
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.filtro {
  flex: 0 0 auto;
}
.interventi {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.preferiti {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>